export default function MediaPreview() {
  const socialPreviewsData = [
    "Preview For Instagram",
    "Preview For Facebook",
    "Preview For Twitter",
  ];
  return (
    <div className="media-preview-container commonPadding">
      <div className="inner-container">
        <h1>
          Optimized For Posts on All Social Media:{" "}
          <span>Facebook, Instagram & Twitter</span>
        </h1>
        <p>
          The design has an aspect ratio of 1:1 which the majority of social
          media recommends. The image quality is also high (1080 * 1080), you
          can further increase the image quality to 2x or more so that the
          design looks sharper.
        </p>
        <div className="preview-container">
          <button className="active">
            Preview For Instagram
            <svg
              width={28}
              height={27}
              viewBox="0 0 28 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.30371 13.6426H21.7403"
                stroke="currentColor"
                strokeWidth="2.20523"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0225 5.92432L21.7408 13.6426L14.0225 21.3609"
                stroke="currentColor"
                strokeWidth="2.20523"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button >
            Preview For Facebook
            <svg
              width={28}
              height={27}
              viewBox="0 0 28 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.30371 13.6426H21.7403"
                stroke="currentColor"
                strokeWidth="2.20523"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0225 5.92432L21.7408 13.6426L14.0225 21.3609"
                stroke="currentColor"
                strokeWidth="2.20523"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button>
            Preview For Twitter
            <svg
              width={28}
              height={27}
              viewBox="0 0 28 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.30371 13.6426H21.7403"
                stroke="currentColor"
                strokeWidth="2.20523"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0225 5.92432L21.7408 13.6426L14.0225 21.3609"
                stroke="currentColor"
                strokeWidth="2.20523"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <img src="/instagram-preview.png" />
    </div>
  );
}
