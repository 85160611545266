import React from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import logo from "../../img/logo.png";

const Header = () => {
  const handleNavClick = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section className="bannerSection">
      <div className="mainBackgroundContainer">
        <div className="bg"></div>
      </div>
      <Container fluid>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Brand>
            <NavLink to="/">
              {" "}
              <img src={logo} alt="logo" />
            </NavLink>
          </Navbar.Brand>

          <div className="m-btn">
            <NavLink className="loginBtn" to="/login">
              Login
            </NavLink>
            <NavLink className="regBtn" to="/accountregistration">
              Register Now
            </NavLink>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              {/* 1 */}
              <NavLink to="/" className="nav-link">
                {" "}
                Home{" "}
              </NavLink>

              {/* 2 */}
              <NavDropdown title="Resources" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <NavLink to="/">Link 1</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/">Link 2</NavLink>
                </NavDropdown.Item>
              </NavDropdown>

              {/* 3 */}
              <NavDropdown title="Downloads" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <NavLink to="/">Link 1</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/">Link 2</NavLink>
                </NavDropdown.Item>
              </NavDropdown>

              {/* 4 */}
              <a
                onClick={() => handleNavClick("plans-and-pricing")}
                className="nav-link"
              >
                {" "}
                Pricing
              </a>
            </Nav>
          </Navbar.Collapse>

          <NavLink className="loginBtn d-btn" to="/login">
            Login
          </NavLink>
          <NavLink className="regBtn d-btn" to="/accountregistration">
            Register Now
          </NavLink>
        </Navbar>
      </Container>
    </section>
  );
};

export default Header;
